<script lang="ts" setup>
const { open } = useNlcModals();
const { data: appInitData } = useAppInitData();

const level = computed(() => appInitData.value?.rankLeague?.currentLevel);
const currentStatus = computed(() => appInitData.value?.rankLeague?.currentSection?.id);
const valueProgress = computed(
	() =>
		level.value?.currentExperience &&
		level.value?.neededExperience &&
		((level.value?.currentExperience * 100) / level.value?.neededExperience || 0)
);
const isProgressBlocked = computed(() => appInitData.value?.rankLeague?.progressBlockedReason);
const { isUserInGame } = useGameModal();

const handleOpenProgress = () => {
	if (isUserInGame.value) {
		if (isProgressBlocked.value) {
			open("LazyOModalGameUnlockVip");
			return;
		}
		open("LazyOModalGameVipOverview");
		return;
	}

	navigateTo("/game");
};
</script>

<template>
	<div class="status-btn" @click="handleOpenProgress">
		<NuxtImg
			class="status"
			:src="`/nuxt-img/vip/status/icon-${currentStatus}.png`"
			format="avif"
			alt="progress"
			width="45"
			height="48"
		/>
		<AProgressCircle
			class="status-progress"
			:value="valueProgress"
			color="var(--custom-9)"
			colorValue="var(--additional-a-3)"
			linecap="round"
			:size="50"
		/>
		<NuxtIcon v-if="isProgressBlocked" name="16/lock" filled class="lock-icon" />
	</div>
</template>

<style lang="scss" scoped>
.status-btn {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
	cursor: pointer;
	position: relative;

	img {
		width: 20px;
		height: auto;
	}

	.lock-icon {
		position: absolute;
		padding: 2px 4px;
		background: linear-gradient(146.82deg, #445284 13.19%, #1b2132 72.62%);
		box-shadow: 0 0 12px 0 #082641;
		border-radius: 50%;
		right: -8px;
		bottom: 0;
	}
}
.status-progress {
	position: absolute;
}
</style>
